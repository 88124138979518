import styles from './Home.module.css';
import logo from './logo.svg';

function Home() {
  return (
    <div className={styles.container}>
      <header className={styles.container}>
        <img src={logo} alt="logo" width="256" />
      </header>
    </div>
  );
}

export default Home;
